<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
    components: {
        Header,
        Footer,
    },
};
</script>

<template>
    <main class="container_black">
        <Header />
        <section class="header_bd">
            <h1 class="ta_center">Our Privacy Policy</h1>
            <p class="ta_center header_article">
                Our mission to help build a better Internet is rooted in the importance we place on establishing trust with our Customers and their
                End Users.To sustain that trust, we are deeply committed to protecting privacy. We will not sell, rent, share, or otherwise disclose
                your personal information or the personal information of End Users to 3rd parties. We will also minimize our use of your data,
                limiting our use to the minimum necessary to provide our services, or as otherwise described in this Policy. We will not expand our
                use of your data without first providing you with notice and the opportunity to consent.
            </p>
        </section>
        <div class="child_wrap container_wrap flex flex_col">
            <section>
                <div>
                    <h1>Policy Application</h1>
                    <br />
                    <p>
                        This Policy applies to Rivet’s collection, use, and disclosure of the information of the following categories of individuals:
                    </p>
                </div>
                <div class="policy_rivet">
                    <div class="pol_app">
                        <h2>Attendes</h2>
                        <p>
                            Individuals who provide their information to Rivet or Rivet representatives when they attend or register to attend
                            Rivet-sponsored events or other events at which Rivet participates.
                        </p>
                    </div>
                    <div class="pol_app">
                        <h2>Website Visitors</h2>
                        <p>
                            Individuals who visit our Websites and who may opt to provide an email address to receive communications from Rivet. For
                            the purposes of this Policy, “Websites” shall refer collectively to
                            <a href="https://rivet.cloud">https://rivet.cloud</a>
                            as well as any other websites Rivet owns and that link to this Policy. For clarity, “Websites” does not include any sites
                            owned or operated by our Customers.
                        </p>
                    </div>
                    <div class="pol_app">
                        <h2>Customers</h2>
                        <p>
                            Those who register on their own or on behalf of an entity or organization to use any of the Rivet Services (as defined
                            below), including administrators on a Customer account. For the purposes of this Policy, “Services” shall refer to all
                            network service solutions marketed for subscription at https://rivet.cloud, including when Services are sold by an
                            authorized partner or reseller.
                        </p>
                    </div>
                    <div class="pol_app">
                        <h2>End Users</h2>
                        <p>
                            Individuals who visit our Customers’ websites and whose information we process in order to provide the Services to our
                            Customers pursuant to our agreements with them.
                        </p>
                    </div>
                </div>
                <article>
                    <p>
                        This Policy does not apply to our Customers’ websites, which may have their own terms and privacy policies.Our Customers are
                        solely responsible for establishing policies for and ensuring compliance with all applicable laws and regulations, including
                        those relating to the collection of personal information in connection with the use of our Services by End Users with whom our
                        Customers interact. Rivet’s Websites and Services are not intended for, nor designed to attract, individuals under the age of
                        eighteen. Rivet does not knowingly collect personally identifiable information from any person under the age of eighteen.
                    </p>
                </article>
            </section>
            <section>
                <h1>Information we collect.</h1>
                <div class="policy_rivet">
                    <div class="pol_app">
                        <h2>Attendees</h2>
                        <p>
                            We may ask for and collect personal information such as your name, address, phone number and email address when you
                            register for or attend a sponsored event or other events at which Rivet (and/or its representatives) participates.
                        </p>
                    </div>
                    <div class="pol_app">
                        <h2>Website Visitors</h2>
                        <p>
                            Name, email address, and other contact information. We ask for and—at your option—collect personal information from you
                            when you submit web forms on our Websites, including opportunities to sign up for and agree to receive email
                            communications from us.
                        </p>
                    </div>
                    <p class="pol_app">
                        We also may ask you to submit such personal information if you choose to use interactive features of the Websites, including
                        participation in surveys, contests, promotions, sweepstakes, requesting customer support, or otherwise communicating with us.
                        We will send such communications in accordance with applicable law.
                    </p>
                    <div class="pol_app">
                        <h2>Log Files.</h2>
                        <p>
                            Just as when you visit and interact with most websites and services delivered via the internet, when you visit our
                            Websites, we gather certain information and store it in log files. This information may include but is not limited to
                            Internet Protocol (IP) addresses, system configuration information, URLs of referring pages, and locale and language
                            preferences.
                        </p>
                    </div>
                    <div class="pol_app">
                        <h2>Cookies and Other Tracking Technologies.</h2>
                        <p>
                            We may use cookies and other information gathering technologies for a variety of purposes, such as providing us with
                            information about how you interact with our Websites and assisting us in our marketing efforts.
                        </p>
                    </div>
                    <p class="pol_app">
                        You can control how websites use cookies by configuring your browser's privacy settings (please refer to your browser's help
                        function to learn more about cookie controls).
                    </p>
                    <h2>
                        <i>"Note that if you disable cookies entirely, Rivet’s Websites may not function properly."</i>
                    </h2>
                    <div class="pol_app">
                        <h2>Interactive Areas.</h2>
                        <p>
                            The Websites may offer publicly accessible blogs, community forums, comments sections, discussion forums, or other
                            interactive features (“Interactive Areas”). If you choose to participate in any of these Interactive Areas, please be
                            aware that any information that you post in an Interactive Area might be read, collected, and used by others who access
                            it.
                        </p>
                    </div>
                    <div class="pol_app">
                        <h2>Customer Account Information.</h2>
                        <p>
                            When you register for a Rivet account, we collect your email address. We will also ask you to enter your organization name
                            in order to participate in the Services. We refer to this information as “Customer Account Information” for the purposes
                            of this Policy. Customer Account Information is required to identify you as a Customer and permit you to access your
                            account(s). By voluntarily providing us with such Customer Account Information, you represent that you are the owner of
                            such personal data or otherwise have the requisite consent to provide it to us
                        </p>
                    </div>
                    <div class="pol_app">
                        <h2>Payment Information.</h2>
                        <p>
                            You are not required to enter your credit card information unless and until you decide to continue with a paid
                            subscription to our Services. When you sign up for one of our paid Services, you must provide billing information. We do
                            not store full credit card numbers or personal account numbers.
                        </p>
                    </div>
                    <div class="pol_app">
                        <h2>End Users:</h2>
                        <p>
                            Rivet provides hosted blockchain client services that our Customers use to interact with blockchain data. As such, we are
                            a conduit for information controlled by others. It is our Customers and their users who are responsible for the content
                            transmitted across our network (e.g., images, written content, graphics, etc.). We collect End Users’ information when
                            they use our Customers’ web3-enabled websites, web applications, and APIs. This information may include but is not limited
                            to IP addresses, system configuration information, and other information about traffic to and from Customers’ websites
                            (collectively, “Log Data”). We collect and use Log Data to operate, maintain, and improve our Services in performance of
                            our obligations under our Customer agreements. For example, Log Data can help us to detect new threats, identify malicious
                            third parties, and provide more robust security protection for our Customers.
                        </p>
                    </div>
                    <div class="pol_app">
                        <h2>Legal Basis for Processing (EEA only):</h2>
                        <p>
                            If you are an individual from the European Economic Area (EEA), please note that our legal basis for collecting and using
                            your personal information will depend on the personal information collected and the specific context in which we collect
                            it. We normally will collect personal information from you only where: (a) we have your consent to do so, (b) where we
                            need your personal information to perform a contract with you (e.g. to deliver the Rivet Services you have requested), or
                            (c) where the processing is in our legitimate interests. Please note that in most cases, if you do not provide the
                            requested information, Rivet will not be able to provide the requested service to you.
                        </p>
                    </div>

                    <p class="pol_app">
                        In some cases, we may also have a legal obligation to collect personal information from you, or may otherwise need the
                        personal information to protect your vital interests or those of another person.
                    </p>

                    <p class="pol_app">
                        Where we rely on your consent to process your personal data, you have the right to withdraw or decline consent at any time.
                        Where we rely on our legitimate interests to process your personal data, you have the right to object.
                    </p>
                    <p class="pol_app">
                        If you have any questions about or need further information concerning the legal basis on which we collect and use your
                        personal information, please contact us at privacy@rivet.cloud.
                    </p>
                </div>
            </section>
            <section>
                <h1>HOW WE USE INFORMATION WE COLLECT.</h1>
                <div class="howweuse flex flex_col">
                    <p>
                        Rivet only processes personal information in a way that is compatible with and relevant for the purpose for which it was
                        collected or authorized. As a general matter, for all categories of data we collect, we may use the information we collect
                        (including personal information, to the extent applicable) to:
                    </p>
                    <br />
                    <p>
                        provide, operate, maintain, improve, and promote the Website and Services; enable you to access and use the Website and
                        Services; process and complete transactions, and send you related information, including purchase confirmations and invoices;
                        send transactional messages, including responses to your comments, questions, and requests; provide customer service and
                        support; and send you technical notices, updates, security alerts, and support and administrative messages; comply with legal
                        obligations as well as to investigate and prevent fraudulent transactions, unauthorized access to the Services, and other
                        illegal activities; and process for other purposes for which we obtain your consent.
                    </p>
                    <br />
                    <p>
                        Information from Third Party Services. We may combine information we collect as described above with personal information we
                        obtain from third parties. For example, we may combine information entered on a Rivet sales submission form with information
                        we receive from a third-party sales intelligence platform vendor to enhance our ability to market our Services to Customers or
                        potential Customers.
                    </p>
                    <br />
                </div>
            </section>
            <section>
                <h1>INFORMATION SHARING.</h1>
                <p>
                    We work with other companies who help us run our business (“Service Providers”). These companies provide services to help us
                    deliver customer support, process credit card payments, manage and contact our existing Customers as well as sales leads, provide
                    marketing support, and otherwise operate and improve our Services. These Service Providers may only process personal information
                    pursuant to our instructions and in compliance both with this Privacy Policy and other applicable confidentiality and security
                    measures and regulations, including our obligations under the EU-US and Swiss-US Privacy Shield frameworks described in Section 6,
                    below.
                </p>
                <p>
                    Specifically, we will not authorize our Service Providers to use any personal information we share with them for their own
                    marketing purposes or for any other purpose than in connection with the services they provide to us.
                </p>
                <h2>
                    In addition to sharing with Service Providers as described above, we may also share your information with others in the following
                    circumstances:
                </h2>
                <p>
                    With our resellers and other sales partners who may help us distribute the Services to Customers; In the event of a merger, sale,
                    change in control, or reorganization of all or part of our business; When we are required to disclose personal information to
                    respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims.
                    Where we have a good-faith belief sharing is necessary to investigate, prevent, or take action regarding illegal activities,
                    suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Service Agreement,
                    or as otherwise required to comply with our legal obligations; or as you may otherwise consent from time to time.
                </p>
                <p>
                    We do not sell, rent, or share personal information with third parties for their direct marketing purposes, including as defined
                    under California Civil Code Sec. 1798.83.
                </p>
            </section>
            <section>
                <h1>DATA SUBJECT RIGHTS.</h1>
                <div class="srg">
                    <h2>Customers</h2>
                    <p>
                        you can access, correct, export, or update your Account Information by editing your profile or organization record at
                        https://rivet.cloud
                    </p>
                </div>
                <div class="srg">
                    <h2>End Users</h2>
                    <p>
                        Rivet has no direct relationship with End Users. Even where “Rivet” may be indicated as the authoritative name server for a
                        domain, unless Rivet is the owner of that domain, we have no control over a domain’s content. Accordingly, we rely upon our
                        Customers to comply with the underlying legal requirements. If an End User requests that we access, correct, update, or delete
                        their information, or no longer wishes to be contacted by one of our Customers that use our Services, we will direct that End
                        User to contact the Customer website(s) with which they interacted directly. Our Customers are solely responsible for ensuring
                        compliance with all applicable laws and regulations with respect to their website users.
                    </p>
                </div>
            </section>
            <section>
                <h1>DATA SECURITY, DATA INTEGRITY, AND ACCESS.</h1>
                <p>
                    We take all reasonable steps to protect information we receive from you from loss, misuse or unauthorized access, disclosure,
                    alteration, and/or destruction. We have put in place appropriate physical, technical, and administrative measures to safeguard and
                    secure your information, and we make use of privacy-enhancing technologies such as encryption. If you have any questions about the
                    security of your personal information, you can contact us at privacy@rivet.cloud.
                </p>
            </section>
            <section>
                <h1>NOTIFICATION OF CHANGES.</h1>
                <p>
                    If we make changes to this Policy that we believe materially impact the privacy of your personal data, we will promptly provide
                    notice of any such changes (and, where necessary, obtain consent), as well as post the updated Policy on this website noting the
                    effective date of any changes.
                </p>
            </section>
            <section>
                <h1>BUSINESS TRANSACTIONS.</h1>
                <p>
                    We may assign or transfer this Policy, as well as information covered by this Policy, in the event of a merger, sale, change in
                    control, or reorganization of all or part of our business.
                </p>
            </section>
            <section>
                <h1>DISPUTE RESOLUTION</h1>
                <p>
                    If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact us at
                    privacy@rivet.cloud.
                </p>
            </section>
            <section>
                <h1>CONTACT INFORMATION</h1>
                <p>For all questions, concerns or issues related to this policy or privacy in general, please contact us at privacy@rivet.cloud.</p>
            </section>
        </div>
        <Footer />
    </main>
</template>

<style lang="scss" scoped>
h3 {
    font-family: "Monument";
    cursor: text;
}
h1 {
    text-transform: capitalize;
}
p {
    a {
        color: rgb(16, 106, 203);
    }
}
h2 {
    font-size: 1.7em;
    text-transform: capitalize;
    color: $grey;
}
body {
    background: #070707;
    .container_wrap {
        @include flex_col(5rem 0px);
        padding: 6rem 0px;
        @include media("<=phone-tab") {
            padding: 5rem 0px;
        }
    }
    .header_article {
        max-width: 65%;
        margin: 0 auto;
        @include media("<=phone-tab") {
            max-width: 90%;
        }
    }
    section {
        @include flex_col(2em 0px);
        .policy_rivet {
            @include flex_col(1.7em 0px);
            .pol_app {
                @include flex_col(0.3rem 0px);
            }
        }
    }
}
</style>
